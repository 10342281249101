<template>
  <div class="rn_ms_section goods_border">
    <!---->
    <div class="rn_ms_box_3">
      <!---->
      <div class="search_wrap">
        <div class="section">
          <h4>상품/서비스</h4>
          <div class="search_box">
            <div class="search_list">
              <h5>카테고리 검색 결과</h5>
              <div class="box">
                <template v-if="goodsCategoryArray.length > 0">
                  <div v-for="(item, index) in goodsCategoryArray" :key="index">
                    <router-link :to="`/market_goods_list/5/${item.cate4_id}`"
                      ><span class="left"
                        >{{ item.cate1_title }} > {{ item.cate2_title }} >
                        {{ item.cate3_title }} >
                        <p
                          style="display: inline"
                          v-html="highlight(item.cate4_title)"
                        ></p> </span
                    ></router-link>
                  </div>
                </template>
                <template v-if="goodsCategoryArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="
                  parseInt(goodsCategoryPageCnt) > parseInt(goodsCategoryPage)
                "
              >
                <router-link to="" @click.native="goodsCategorySearch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
            <div class="search_list">
              <h5>단어 검색 결과</h5>
              <div class="box">
                <template v-if="textGoodsArray.length > 0">
                  <div v-for="(item, index) in textGoodsArray" :key="index">
                    <router-link
                      :to="`/market_goods_detail/${item.cate1_id}/${item.goods_seq}`"
                      ><span class="left">
                        <p
                          style="display: inline"
                          v-html="highlight(item.goods_name)"
                        ></p> </span
                    ></router-link>
                  </div>
                </template>
                <template v-if="textGoodsArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="parseInt(textGoodsPageCnt) > parseInt(textGoodsPage)"
              >
                <router-link to="" @click.native="textGoodsSerch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <h4>램 가게/제휴몰</h4>
          <div class="search_box">
            <div class="search_list">
              <h5>램가게 검색 결과</h5>
              <div class="box">
                <template v-if="providerArray.length > 0">
                  <div v-for="(item, index) in providerArray" :key="index">
                    <router-link :to="`/mini_shop/${item.provider_seq}`"
                      ><span class="left">
                        <p
                          style="display: inline"
                          v-html="highlight(item.provider_name)"
                        ></p></span
                    ></router-link>
                  </div>
                </template>
                <template v-if="providerArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="parseInt(providerPageCnt) > parseInt([providerPage])"
              >
                <router-link to="" @click.native="providerSerch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <h4>리뷰</h4>
          <div class="search_box">
            <div class="search_list">
              <h5>카테고리 검색 결과</h5>
              <div class="box">
                <template
                  v-if="categoryArray.length > 0 && parseInt(menuId) === 1"
                >
                  <div v-for="(item, index) in categoryArray" :key="index">
                    <router-link
                      :to="`/review/goods_main/${item.cate1_id}/${item.category_code}`"
                      ><span class="left"
                        >{{ item.cate1_title }} > {{ item.cate2_title }} >
                        {{ item.cate3_title }} >
                        <p
                          style="display: inline"
                          v-html="highlight(item.cate4_title)"
                        ></p>
                      </span>
                    </router-link>
                  </div>
                </template>
                <template
                  v-if="categoryArray.length > 0 && parseInt(menuId) === 3"
                >
                  <div v-for="(item, index) in categoryArray" :key="index">
                    <router-link
                      :to="`/review/news_main/${item.m1_seq}?category=${item.m2_seq}`"
                      ><span class="left"
                        >{{ item.m1_title }} >
                        <p
                          style="display: inline"
                          v-html="highlight(item.m2_title)"
                        ></p>
                      </span>
                    </router-link>
                  </div>
                </template>
                <template v-if="categoryArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="parseInt(categoryPageCnt) > parseInt(categoryPage)"
              >
                <router-link to="" @click.native="categorySearch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
            <div class="search_list">
              <h5>'{{ searchText }}' (을)를 포함한 카테고리 검색 결과</h5>
              <div class="box">
                <template
                  v-if="textCategoryArray.length > 0 && parseInt(menuId) === 1"
                >
                  <div v-for="(item, index) in textCategoryArray" :key="index">
                    <router-link
                      :to="`/review/goods_main/${item.category_code}?goods_seq=${item.goods_seq}`"
                      ><span class="left"
                        >{{ item.cate1_title }} > {{ item.cate2_title }} >
                        {{ item.cate3_title }} > {{ item.cate4_title }} >
                        <p
                          style="display: inline"
                          v-html="highlight(item.main_goods_name)"
                        ></p>
                      </span>
                    </router-link>
                  </div>
                </template>
                <template v-if="textCategoryArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="
                  parseInt(textCategoryPageCnt) > parseInt(textCategoryPage)
                "
              >
                <router-link to="" @click.native="textCategorySearch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
            <div class="search_list">
              <h5>단어 검색 결과</h5>
              <div class="box">
                <template
                  v-if="textTitleArray.length > 0 && parseInt(menuId) === 1"
                >
                  <div v-for="(item, index) in textTitleArray" :key="index">
                    <router-link
                      :to="`/review/goods_main/${item.category_code}?${
                        parseInt(item.option_category1) === 1
                          ? 'goods_seq=' + item.goods_seq
                          : ''
                      }&option1=${item.option_category1}&option2=${
                        item.option_category2
                      }&option3=${item.option_category3}&seq=${item.seq}`"
                      ><span class="left">
                        <p
                          style="display: inline"
                          v-html="highlight(item.title)"
                        ></p></span
                    ></router-link>
                  </div>
                </template>
                <template
                  v-if="textTitleArray.length > 0 && parseInt(menuId) === 3"
                >
                  <div v-for="(item, index) in textTitleArray" :key="index">
                    <router-link
                      :to="`/review/news_main/${item.option_category1}?category=${item.option_category2}&seq=${item.seq}`"
                      ><span class="left">
                        <p
                          style="display: inline"
                          v-html="highlight(item.title)"
                        ></p></span
                    ></router-link>
                  </div>
                </template>
                <template v-if="textTitleArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="parseInt(textTitlePageCnt) > parseInt(textTitlePage)"
              >
                <router-link to="" @click.native="textSerch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <h4>리뷰어</h4>
          <div class="search_box">
            <div class="search_list">
              <h5>리뷰어 닉네임 검색 결과</h5>
              <div class="box">
                <template v-if="reviewerArray.length > 0">
                  <div v-for="(item, index) in reviewerArray" :key="index">
                    <router-link
                      :to="`/review/reviewer_goods/${item.member_seq}?from=search`"
                      ><span class="left"
                        ><p
                          style="display: inline"
                          v-html="highlight(item.reviewer_nick)"
                        ></p></span
                    ></router-link>
                  </div>
                </template>
                <template v-if="reviewerArray.length <= 0">
                  <div>
                    <p class="no_data">검색 결과가 없습니다.</p>
                  </div>
                </template>
              </div>
              <div
                class="search_more"
                v-if="parseInt(reviewerPageCnt) > parseInt(reviewerPage)"
              >
                <router-link to="" @click.native="reviewerSerch"
                  >{{ limit }}개 더보기<img
                    src="@/assets/images/sub/my_down_ico.png"
                    alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menuId: 1,
      searchText: this.$route.query.search || null,
      categoryPage: 1,
      textCategoryPage: 1,
      textPage: 1,
      reviewerPage: 1,
      goodsCategoryPage: 1,
      textGoodsCategoryPage: 1,
      textGoodsPage: 1,
      textTitlePage: 1,
      providerPage: 1,
    };
  },
  created() {
    this.$store.dispatch("search/resetSearch");
    this.getTotalSearch();
  },
  computed: {
    ...mapState("search", [
      "result",
      "msg",
      "categoryArray",
      "categoryPageCnt",
      "textCategoryArray",
      "textCategoryPageCnt",
      "reviewerArray",
      "reviewerPageCnt",
      "goodsCategoryArray",
      "goodsCategoryPageCnt",
      "textGoodsArray",
      "textGoodsPageCnt",
      "textTitleArray",
      "textTitlePageCnt",
      "providerArray",
      "providerPageCnt",
      "limit",
    ]),
  },
  methods: {
    highlight(text) {
      if (!text) {
        return false;
      }
      let replaceText = text;
      const searchText = [...this.searchText.replace(/\s/g, "")];
      if (replaceText) {
        searchText.map((word) => {
          let regexp = new RegExp(
            word
              .replace(/\./g, "&#46;")
              .replace(/&/g, "&amp;")
              .replace(/>/g, "&gt;")
              .replace(/</g, "&lt;")
              .replace(/\//g, "&#47;")
              .replace(/"/g, "&quot;"),
            "gi"
          );
          replaceText = replaceText.replace(regexp, `<span>${word}</span>`);
        });
      }
      return replaceText;
    },
    getSearch(text, menu) {
      this.searchText = text;
      this.menuId = 1;
      this.categoryPage = 1;
      this.textCategoryPage = 1;
      this.textPage = 1;
      this.reviewerPage = 1;
      this.goodsCategoryPage = 1;
      this.textGoodsCategoryPage = 1;
      this.textGoodsPage = 1;
      this.textTitlePage = 1;
      this.providerPage = 1;
      this.getTotalSearch(true);
    },
    async categorySearch() {
      await this.$store.dispatch("search/categorySearch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.categoryPage,
      });
      this.categoryPage++;
    },
    async textCategorySearch() {
      await this.$store.dispatch("search/textCategorySearch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.textCategoryPage,
      });
      this.textCategoryPage++;
    },
    async textSerch() {
      await this.$store.dispatch("search/textSerch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.textTitlePage,
      });
      this.textTitlePage++;
    },
    async goodsCategorySearch() {
      await this.$store.dispatch("search/goodsCategorySearch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.goodsCategoryPage,
      });
      this.goodsCategoryPage++;
    },
    async reviewerSerch() {
      await this.$store.dispatch("search/reviewerSerch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.reviewerPage,
      });
      this.reviewerPage++;
    },
    async textGoodsSerch() {
      await this.$store.dispatch("search/textGoodsSerch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.textGoodsPage,
      });
      this.textGoodsPage++;
    },
    async providerSerch() {
      await this.$store.dispatch("search/providerSerch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        page: this.providerPage,
      });
      this.providerPage++;
    },
    async getTotalSearch(reset = false) {
      await this.$store.dispatch("search/getTotalSearch", {
        search_text: this.searchText,
        main_menu_seq: this.menuId,
        reset,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no_data {
  text-align: center;
}
</style>
